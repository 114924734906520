import React, { useState, useEffect } from 'react';

import Structure from '../../components/structure/Structure'
import Layout from "../../components/layout"


const Index = () => {


    return (
        <Layout>
            <Structure />
        </Layout>
    )
}

export default Index
